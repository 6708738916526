import type { FC } from 'react';
import React, { useEffect, useContext } from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N300A } from '@atlaskit/theme/colors';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useSessionData } from '@confluence/session-data';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { SPAViewContext } from '@confluence/spa-view-context';

import { SECTION_MESSAGE_APPEARANCE_TYPES } from '../constants';
import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';
import { SectionMessage } from '../UpsellLooknFeel/SectionMessage';

import { ExportPLUpsellCohortEstimator } from './ExportPLUpsellCohortEstimator';

interface ExportPLUpsellProps {
	onClick?(): void;
}

const i18n = defineMessages({
	title: {
		id: 'change-edition.export-upsell.title',
		defaultMessage: 'Share as public link',
		description: 'info message title for public link upsell',
	},
	text: {
		id: 'change-edition.export-upsell.text',
		defaultMessage:
			'It’s easier to make changes and keep everyone on the same page with a public link.',
		description: 'info message text for public link upsell',
	},
});

const enum EXPORT_UPSELL_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	VARIATION = 'variation',
}

const PL_EXPORTS_UPGRADE_FLAG = 'public_links_export_upgrade';
const PL_EXPORT_UPSELL_SOURCE = 'publicLinksExportUpsell';
const PL_EXPORT_UPSELL_SCREEN = 'publicLinksExportUpsellScreen';
const LEARN_MORE_PUBLIC_LINKS_HREF =
	'https://support.atlassian.com/confluence-cloud/docs/share-content-externally-with-public-links/';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExportsPLUpsellContainer = styled.div({
	maxWidth: '280px',
	cursor: 'default',
	borderTop: `1px solid ${token('color.border', N300A)}`,
});

/**
 * Counts elapsed time in ms since a given start point.
 * Rounds it to the lower nearest *msBucketSize* threshold
 * e.g. If *msBucketSize* is 200, an elapsed time of 486 becomes 400.
 * Maxes out at 12x the threshold. This lets us bucket users
 * based on how much time they looked at the export menu
 */
const msSince = (startTime: number, msBucketSize: number = 250) => {
	const rawMsPassed = Date.now() - startTime;
	const rounded = Math.floor(rawMsPassed / msBucketSize) * msBucketSize;
	return Math.min(rounded, msBucketSize * 12);
};

export const ExportsPLUpsellComponent: FC<ExportPLUpsellProps> = ({ onClick }) => {
	const flagValue = FeatureGates.getExperimentValue<EXPORT_UPSELL_COHORTS>(
		PL_EXPORTS_UPGRADE_FLAG,
		'Cohort',
		EXPORT_UPSELL_COHORTS.NOT_ENROLLED,
		{
			fireExperimentExposure: false,
		},
	);
	const isVariation = flagValue === EXPORT_UPSELL_COHORTS.VARIATION;

	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isSiteAdmin } = useContext(SPAViewContext);

	const shouldExposeExportUpsell =
		[EXPORT_UPSELL_COHORTS.VARIATION, EXPORT_UPSELL_COHORTS.CONTROL].includes(flagValue) &&
		edition === ConfluenceEdition.FREE &&
		isSiteAdmin;

	const shouldShowExportUpsell = edition === ConfluenceEdition.FREE && isVariation;

	useEffect(() => {
		const exposedAt = Date.now();

		if (shouldExposeExportUpsell) {
			// Statsig exposure event
			FeatureGates.manuallyLogExperimentExposure(PL_EXPORTS_UPGRADE_FLAG);

			// Regular exposure event for sanity check
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					actionSubject: 'feature',
					action: 'exposed',
					source: PL_EXPORT_UPSELL_SOURCE,
					attributes: {
						flagKey: PL_EXPORTS_UPGRADE_FLAG,
						value: flagValue,
					},
				},
			}).fire();
		}

		// Dwell event to filter out accidental exposures
		return () => {
			if (shouldExposeExportUpsell) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						actionSubject: PL_EXPORT_UPSELL_SCREEN,
						action: 'dwelled',
						source: PL_EXPORT_UPSELL_SOURCE,
						attributes: {
							ms: msSince(exposedAt),
						},
					},
				}).fire();
			}
		};
	}, [createAnalyticsEvent, flagValue, shouldExposeExportUpsell]);

	return (
		<>
			{shouldShowExportUpsell && (
				<ExportsPLUpsellContainer onClick={(e) => e.stopPropagation()}>
					<SectionMessage
						title={<FormattedMessage {...i18n.title} />}
						body={<FormattedMessage {...i18n.text} />}
						appearanceType={SECTION_MESSAGE_APPEARANCE_TYPES.INLINE}
						source={PL_EXPORT_UPSELL_SOURCE}
						learnMoreLink={{
							href: LEARN_MORE_PUBLIC_LINKS_HREF,
							onClick,
						}}
						targetEdition={ConfluenceEdition.STANDARD}
						onUpFlowOpen={onClick}
						withGradient={false}
					/>
				</ExportsPLUpsellContainer>
			)}
			<ExportPLUpsellCohortEstimator />
		</>
	);
};

export const ExportsPLUpsell = withTransparentErrorBoundary({
	attribution: Attribution.EXTERNAL_COLLAB,
})(ExportsPLUpsellComponent);
