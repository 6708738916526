import React, { useCallback } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useQuery } from 'react-apollo';

import Button from '@atlaskit/button';
import type { ContentProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Popup from '@atlaskit/popup';

import { CONTENT_OWNERSHIP_DIALOG_EXPERIENCE } from '@confluence/experience-tracker';
import { ProfileCardWrapper } from '@confluence/profile';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { useExperienceTrackerContext } from './content-ownership-helpers';
import { ContentOwnershipContextConsumer } from './ContentOwnershipContext';
import { ContentOwnerQuery } from './queries/ContentOwnerQuery.graphql';
import type {
	ContentOwnerQuery as ContentOwnerQueryType,
	ContentOwnerQueryVariables,
} from './queries/__types__/ContentOwnerQuery';
import { useIsAllowedToChangeOwner } from './useIsAllowedToChangeOwner';

const i18n = defineMessages({
	creator: {
		id: 'content-ownership.content-owner-popup.creator',
		defaultMessage: '<b>Creator</b>',
		description: 'Label for a dropdown menu that informs users who created the content',
	},
	owner: {
		id: 'content-ownership.content-owner-popup.owner',
		defaultMessage: '<b>Owner</b>',
		description: 'Label for a dropdown menu that informs users who owns the content',
	},
	changeOwner: {
		id: 'content-ownership.content-owner-popup.change-owner',
		defaultMessage: 'Change owner',
		description: 'Text for a dropdown menu item that lets users change the owner of content',
	},
	ariaLabel: {
		id: 'content-ownership.content-owner-popup.ariaLabel',
		defaultMessage: 'More information about {name}',
		description: 'Aria label attribute of the text displaying a creator or owner name',
	},
});

// fixed dimensions
const contentOwnerContainerStyles = xcss({
	width: '232px',
	height: '180px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
	padding: 'space.250',
});

const contentOwnerViewerContainerStyles = xcss({
	width: '232px',
	height: '140px',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignItems: 'flex-start',
	padding: 'space.250',
});

const contentOwnerItemStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
});

const contentCreatorItemStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingBottom: 'space.100',
});

const contentOwnerChangeItemStyles = xcss({
	marginTop: 'space.100',
});

const contentOwnerNameItemStyles = xcss({
	marginTop: 'space.025',
	textDecoration: 'underline',
	color: 'color.text',
	':hover': {
		color: 'color.link',
		cursor: 'pointer',
	},
});

type UserData = {
	userId: string;
	fullName: string;
	isAnonymous: boolean;
};

interface ContentOwnerDetailsPanelWrapperProps {
	ownedBy: UserData;
	createdBy: UserData;
	closePopup: () => void;
	setInitialFocusRef?: ContentProps['setInitialFocusRef'];
}

const ContentOwnerDetailsPanelName = ({ userId, fullName, ariaLabel }) => {
	return (
		<Box xcss={contentOwnerNameItemStyles} role="tooltip" aria-label={ariaLabel}>
			<ProfileCardWrapper userId={userId} position="left-start" offset={[0, 28]}>
				{fullName}
			</ProfileCardWrapper>
		</Box>
	);
};

const ContentOwnerDetailsPanel = ({
	ownedBy,
	createdBy,
	closePopup,
	openModal,
	setInitialFocusRef,
}) => {
	const intl = useIntl();
	const experienceTracker = useExperienceTrackerContext();
	const onClickChangeOwner = useCallback(() => {
		openModal();
		closePopup();
		experienceTracker.start({
			name: CONTENT_OWNERSHIP_DIALOG_EXPERIENCE,
		});
	}, [closePopup, openModal, experienceTracker]);
	const isAllowedToChange = useIsAllowedToChangeOwner(ownedBy.userId);

	return (
		<Box
			xcss={[isAllowedToChange ? contentOwnerContainerStyles : contentOwnerViewerContainerStyles]}
		>
			<Box xcss={contentCreatorItemStyles}>
				<FormattedMessage
					{...i18n.creator}
					values={{
						b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
					}}
				/>
				<ContentOwnerDetailsPanelName
					userId={createdBy.userId}
					fullName={createdBy.fullName}
					ariaLabel={intl.formatMessage(i18n.ariaLabel, { name: createdBy.fullName })}
				/>
			</Box>
			<Box xcss={contentOwnerItemStyles}>
				<FormattedMessage
					{...i18n.owner}
					values={{
						b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
					}}
				/>
				<ContentOwnerDetailsPanelName
					userId={ownedBy.userId}
					fullName={ownedBy.fullName}
					ariaLabel={intl.formatMessage(i18n.ariaLabel, { name: ownedBy.fullName })}
				/>
			</Box>
			{isAllowedToChange && (
				<Box xcss={contentOwnerChangeItemStyles}>
					<Button
						onClick={onClickChangeOwner}
						appearance="link"
						spacing="none"
						ref={setInitialFocusRef}
					>
						{intl.formatMessage(i18n.changeOwner)}
					</Button>
				</Box>
			)}
		</Box>
	);
};

const ContentOwnerDetailsPanelWrapper: React.FC<ContentOwnerDetailsPanelWrapperProps> = ({
	ownedBy,
	createdBy,
	closePopup,
	setInitialFocusRef,
}) => {
	if (!ownedBy || !createdBy) {
		return null;
	}

	return (
		<ContentOwnershipContextConsumer>
			{({ openModal }) => (
				<ContentOwnerDetailsPanel
					ownedBy={ownedBy}
					createdBy={createdBy}
					closePopup={closePopup}
					openModal={openModal}
					setInitialFocusRef={setInitialFocusRef}
				/>
			)}
		</ContentOwnershipContextConsumer>
	);
};

interface ContentOwnerPopupComponentProps {
	contentId: string;
	itemId: string;
	itemLabel: string | null;
	isOpen: boolean;
	onClick: () => void;
	onCloseParent?: () => void;
	onClosePopup: () => void;
	onMouseEnter?: () => void;
}

export const ContentOwnerPopupComponent: React.FC<ContentOwnerPopupComponentProps> = ({
	contentId,
	itemId,
	itemLabel,
	isOpen,
	onClick,
	onCloseParent,
	onClosePopup,
	onMouseEnter,
}) => {
	const isContentOwnerSupportedForAllTypes = useBooleanFeatureFlag(
		'confluence.frontend.content.owner.support.all.types',
	);

	const closeOwnerDialog = () => {
		onClosePopup();
		onCloseParent?.();
	};

	const { data: ownerHistoryData } = useQuery<ContentOwnerQueryType, ContentOwnerQueryVariables>(
		ContentOwnerQuery,
		{
			variables: {
				contentId,
			},
			skip: !isContentOwnerSupportedForAllTypes || !contentId,
		},
	);
	const toUserData = (user) => ({
		userId: user?.accountId ?? undefined,
		fullName: user?.displayName,
		isAnonymous: !user?.accountId,
	});
	const ownedBy = toUserData(ownerHistoryData?.singleContent?.history?.ownedBy);
	const createdBy = toUserData(ownerHistoryData?.singleContent?.history?.createdBy);

	return (
		<Popup
			key={`container-${itemId}`}
			isOpen={isOpen}
			onClose={onClosePopup}
			// @ts-ignore onBlur not a supported Popup prop, but works as intended. Keep this around until live pages behavior is finalized
			onBlur={onClosePopup}
			placement="left-start"
			content={({ setInitialFocusRef }) => (
				<ContentOwnerDetailsPanelWrapper
					ownedBy={ownedBy}
					createdBy={createdBy}
					closePopup={closeOwnerDialog}
					setInitialFocusRef={setInitialFocusRef}
				/>
			)}
			trigger={(triggerProps) => (
				<DropdownItem
					{...triggerProps}
					onClick={onClick}
					// @ts-ignore onMouseEnter not a supported Popup prop, but works as intended. Keep this around until live pages behavior is finalized
					onMouseEnter={onMouseEnter}
					isSelected={isOpen}
					elemAfter={<ChevronRightIcon primaryColor={token('color.icon.subtle', '')} label="" />}
				>
					<span>{itemLabel}</span>
				</DropdownItem>
			)}
		/>
	);
};
