import React, { useState, useContext, useCallback, Fragment } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { useMutation } from '@apollo/react-hooks';
import type { ExecutionResult } from '@apollo/react-common';
import type { ApolloError } from 'apollo-client';

import AkModalDialog, {
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalBody,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';
import ButtonGroup from '@atlaskit/button/button-group';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { Flex, Stack } from '@atlaskit/primitives';

import {
	CONVERT_PAGE_TO_FOLDER_EXPERIENCE,
	ExperienceTrackerContext,
	stopExperienceOnError,
} from '@confluence/experience-tracker';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { ScreenEvent } from '@confluence/analytics';
import { FOLDER_VIEW } from '@confluence/named-routes';
import { ErrorDisplay, isExpectedApolloError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import { ConvertPageToFolderMutation } from './ConvertPageToFolderMutation.graphql';
import type {
	ConvertPageToFolderMutation as ConvertPageToFolderMutationType,
	ConvertPageToFolderMutationVariables,
} from './__types__/ConvertPageToFolderMutation';

const COMPONENT_NAME = 'ConvertPageToFolderDialogModal';
const source = 'VIEW_PAGE';

type ConvertPageToFolderDialogProps = {
	spaceKey: string;
	contentId: string;
	contentTitle?: string | null;
	onClose: () => void;
};

const ConvertPageToFolderDialogComponent = ({
	spaceKey,
	contentId,
	contentTitle,
	onClose,
	flags,
}: ConvertPageToFolderDialogProps & WithFlagsProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { push } = useRouteActions();

	const [isConverting, setIsConverting] = useState(false);

	const [convertPageToFolderMutation, { error: convertPageToFolderError }] = useMutation<
		ConvertPageToFolderMutationType,
		ConvertPageToFolderMutationVariables
	>(ConvertPageToFolderMutation);

	const handleOnClose = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'clicked',
				actionSubject: COMPONENT_NAME,
				actionSubjectId: `${COMPONENT_NAME}CancelButton`,
				source,
			},
		}).fire();
		onClose();
	}, [createAnalyticsEvent, onClose]);

	const convertPageToFolderSuccess = (data: ExecutionResult<ConvertPageToFolderMutationType>) => {
		setIsConverting(false);
		experienceTracker.succeed({
			name: CONVERT_PAGE_TO_FOLDER_EXPERIENCE,
		});

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				actionSubject: COMPONENT_NAME,
				action: 'success',
				attributes: undefined,
				source,
			},
		}).fire();

		void flags.showFlag({
			type: 'success-circle',
			title: formatMessage(i18n.successFlagTitle),
			description: contentTitle
				? formatMessage(i18n.successFlagDescription, { title: contentTitle })
				: formatMessage(i18n.successFlagDescriptionPlaceholderTitle),
			close: 'auto',
		});

		const contentId = data?.data?.convertToFolder?.content?.id;
		if (contentId) {
			const newlyCreatedFolderUrl = FOLDER_VIEW.toUrl({ spaceKey, contentId });
			newlyCreatedFolderUrl && push(newlyCreatedFolderUrl);
		}

		onClose();
	};

	const showErrorFlag = ({
		title,
		description,
		actions,
	}: {
		title: string;
		description: string;
		actions?: { content: string; onClick: () => void }[];
	}) => {
		void flags.showFlag({ type: 'error', title, description, close: 'auto', actions });
	};

	const abortExperienceOnExpectedError = (error: ApolloError) => {
		markErrorAsHandled(error);
		experienceTracker.abort({
			name: CONVERT_PAGE_TO_FOLDER_EXPERIENCE,
			reason: 'Expected Apollo error',
		});
	};

	const convertPageToFolderFailure = (error: ApolloError) => {
		if (isPageEditRestrictedError(error)) {
			showErrorFlag({
				title: formatMessage(i18n.editPermissionErrorFlagTitle),
				description: formatMessage(i18n.editPermissionErrorFlagDescription),
			});
			abortExperienceOnExpectedError(error);
		} else if (isNameCollisionError(error)) {
			showErrorFlag({
				title: formatMessage(i18n.nameCollisionErrorFlagTitle),
				description: formatMessage(i18n.nameCollisionErrorFlagDescription),
			});
			abortExperienceOnExpectedError(error);
		} else {
			showErrorFlag({
				title: formatMessage(i18n.defaultErrorFlagTitle),
				description: contentTitle
					? formatMessage(i18n.defaultErrorFlagDescription, { title: contentTitle })
					: formatMessage(i18n.defaultErrorFlagDescriptionPlaceholderTitle),
				actions: [
					{
						content: formatMessage(i18n.defaultErrorFlagAction),
						onClick: handlePageToFolderConversion,
					},
				],
			});

			if (isExpectedApolloError(error)) {
				abortExperienceOnExpectedError(error);
				return;
			}
			stopExperienceOnError(CONVERT_PAGE_TO_FOLDER_EXPERIENCE, error);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					actionSubject: COMPONENT_NAME,
					action: 'failed',
					attributes: { error },
					actionSubjectId: `${COMPONENT_NAME}ConvertButton`,
					source,
				},
			}).fire();
		}
	};

	const handlePageToFolderConversion = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: COMPONENT_NAME,
				actionSubjectId: `${COMPONENT_NAME}ConvertButton`,
				source,
			},
		}).fire();

		setIsConverting(true);
		experienceTracker.start({
			name: CONVERT_PAGE_TO_FOLDER_EXPERIENCE,
		});

		convertPageToFolderMutation({
			variables: {
				id: contentId,
			},
		})
			.then((data) => {
				convertPageToFolderSuccess(data);
			})
			.catch((error) => {
				convertPageToFolderFailure(error);
				setIsConverting(false);
			});
	};

	return (
		<Fragment>
			<AkModalDialog
				width="450px"
				key="ConvertPageToFolderDialog"
				onClose={handleOnClose}
				shouldScrollInViewport
			>
				<ModalBody>
					<ModalHeader>
						<ModalTitle>
							<FormattedMessage {...i18n.dialogHeader} />
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<FormattedMessage {...i18n.dialogDescription} />
					</ModalBody>
					<ModalFooter>
						<Stack space="space.200">
							<Flex justifyContent="end">
								<ButtonGroup>
									<Button onClick={handleOnClose} appearance="subtle">
										<FormattedMessage {...i18n.cancel} />
									</Button>

									<Button
										onClick={handlePageToFolderConversion}
										appearance="primary"
										isLoading={isConverting}
									>
										<FormattedMessage {...i18n.dialogSubmitText} />
									</Button>
								</ButtonGroup>
							</Flex>
						</Stack>
					</ModalFooter>
				</ModalBody>
			</AkModalDialog>
			<ScreenEvent
				name="convertPageToFolderDialogModalScreen"
				id="convertPageToFolderModalScreenEvent"
			/>
			{convertPageToFolderError && <ErrorDisplay error={convertPageToFolderError} />}
		</Fragment>
	);
};

export const ConvertPageToFolderDialog = withFlags(ConvertPageToFolderDialogComponent);

const isPageEditRestrictedError = (error: ApolloError): boolean =>
	error.message.includes('User does not have permissions to perform this action');

const isNameCollisionError = (error: ApolloError): boolean =>
	error.message.includes('A folder exists with the same title in this space');

const i18n = defineMessages({
	dialogHeader: {
		id: 'folder.convert-page-to-folder.dialog.header',
		description: 'Heading for convert page to folder dialog',
		defaultMessage: 'Convert this page into a folder?',
	},
	dialogDescription: {
		id: 'folder.convert-page-to-folder.dialog.description',
		description: 'Description for convert page to folder dialog',
		defaultMessage:
			'We’ll archive the original page, so you don’t need to worry about losing your work.',
	},
	cancel: {
		id: 'folder.convert-page-to-folder.dialog.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for convert page to folder dialog',
	},
	dialogSubmitText: {
		id: 'folder.convert-page-to-folder.dialog.submit',
		defaultMessage: 'Convert',
		description: 'Text for completing conversion of page to folder',
	},
	successFlagTitle: {
		id: 'folder.convert-page-to-folder.flags.success-message.title',
		defaultMessage: 'Conversion complete',
		description: 'Flag title shown after conversion of page to folder succeeds',
	},
	successFlagDescription: {
		id: 'folder.convert-page-to-folder.flags.success-message.description',
		defaultMessage: 'We’ve finished converting “{title}” to a folder.',
		description: 'Flag description shown after conversion of page to folder succeeds',
	},
	successFlagDescriptionPlaceholderTitle: {
		id: 'folder.convert-page-to-folder.flags.success-message.description.placeholder-title',
		defaultMessage: 'We’ve finished converting the page to a folder.',
		description: 'Flag description shown after conversion of page to folder succeeds',
	},
	defaultErrorFlagTitle: {
		id: 'folder.convert-page-to-folder.flags.error-message.title',
		defaultMessage: 'We couldn’t convert the page to a folder',
		description: 'Flag title shown after conversion of page to folder failed',
	},
	defaultErrorFlagDescription: {
		id: 'folder.convert-page-to-folder.flags.error-message.description',
		defaultMessage: 'Something went wrong, and we were unable to convert “{title}” to a folder.',
		description: 'Flag description shown after conversion of page to folder failed',
	},
	defaultErrorFlagDescriptionPlaceholderTitle: {
		id: 'folder.convert-page-to-folder.flags.error-message.description.placeholder-title',
		defaultMessage: 'Something went wrong, and we were unable to convert the page to a folder.',
		description: 'Flag description shown after conversion of page to folder failed',
	},
	defaultErrorFlagAction: {
		id: 'folder.convert-page-to-folder.flags.error-message.action',
		defaultMessage: 'Try again',
		description:
			'Flag action button shown after conversion of page to folder failed. Clicking on this will retry the folder conversion operation',
	},
	editPermissionErrorFlagTitle: {
		id: 'folder.convert-page-to-folder.flags.error-message.edit-permissions.title',
		defaultMessage: 'Request edit access to continue',
		description:
			'Flag title shown after conversion of page to folder failed due to user not having edit permissions for the page',
	},
	editPermissionErrorFlagDescription: {
		id: 'folder.convert-page-to-folder.flags.error-message.edit-permissions.description',
		defaultMessage: 'To convert this page to a folder, you’ll need permission to edit it.',
		description:
			'Flag description shown after conversion of page to folder failed due to user not having edit permissions for the page',
	},
	nameCollisionErrorFlagTitle: {
		id: 'folder.convert-page-to-folder.flags.error-message.name-collision.title',
		defaultMessage: 'Rename this page to continue',
		description:
			'Flag title shown after conversion of page to folder failed due to the page, which is renamed so that a folder with the same name can be created, having the same title as another page',
	},
	nameCollisionErrorFlagDescription: {
		id: 'folder.convert-page-to-folder.flags.error-message.name-collision.description',
		defaultMessage:
			'To avoid duplicate titles, you’ll need to rename this page before we can convert it to a folder and archive the original.',
		description:
			'Flag description shown after conversion of page to folder failed due to the page, which is renamed so that a folder with the same name can be created, having the same title as another page',
	},
});
