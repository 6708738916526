import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	title: {
		id: 'page-recommendations.eop.title',
		defaultMessage: 'Related pages',
		description: 'Title of the end of page recommendations',
	},
	feedback: {
		id: 'page-recommendations.eop.feedback.button',
		defaultMessage: 'Give feedback',
		description: 'Button for feedback on end of page recommendations',
	},
	info: {
		id: 'page-recommendations.eop.feedback.info',
		defaultMessage:
			'These suggestions are automatically generated based on pages that are viewed or are organized together. Suggestions update over time and respect page restrictions.',
		description: 'Info description for end of page recommendations',
	},
	hide: {
		id: 'page-recommendations.eop.feedback.hide',
		defaultMessage: 'Hide for everyone...',
		description: 'Button to hide end of page recommendations',
	},
	modal_title: {
		id: 'page-recommendations.eop.hide.modal.title',
		defaultMessage: 'Hide related pages for <b>everyone</b>?',
		description: 'Title of the modal that opens to hide end of page recommendations',
	},
	modal_cancel: {
		id: 'page-recommendations.eop.hide.modal.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button for the modal that opens to hide end of page recommendations',
	},
	modal_hide: {
		id: 'page-recommendations.eop.hide.modal.hide.button',
		defaultMessage: 'Hide for everyone',
		description:
			'Confirmation of hide for the modal that opens to hide end of page recommendations',
	},
	modal_info: {
		id: 'page-recommendations.eop.hide.modal.info',
		defaultMessage: 'Suggestions for this page will be hidden from everyone.',
		description:
			'Information about hiding recommendations for the modal that opens to hide end of page recommendations',
	},
	modal_info_show: {
		id: 'page-recommendations.eop.hide.modal.info.show',
		defaultMessage: 'You can show related pages again under advanced details for this page.',
		description:
			'Information about how to show the recommendations again for the modal that opens to hide end of page recommendations',
	},
	space_setting_info: {
		id: 'page-recommendations.eop.space.setting.info',
		defaultMessage:
			'Related pages are automatically generated suggestions that are based on pages viewed or organized together. These will appear as a series of cards at the bottom of some pages, and may update over time.',
		description: 'Info about related pages within the space settings section',
	},
	space_setting_hide_toggle: {
		id: 'page-recommendations.eop.space.setting.hide.toggle',
		defaultMessage: 'Related pages',
		description: 'Label for related pages hide toggle for space settings',
	},
	space_setting_default: {
		id: 'page-recommendations.eop.space.setting.default',
		defaultMessage: 'Default behavior',
		description: 'Label for related pages dropdown selection for default hide/show behavior',
	},
	space_setting_on: {
		id: 'page-recommendations.eop.space.setting.on',
		defaultMessage:
			'Related pages are on for this space, but anyone with edit permissions can hide suggestions on a page.',
		description: 'Description of related pages setting when they are turned on for entire space',
	},
	space_setting_on_hidden: {
		id: 'page-recommendations.eop.space.setting.on.hidden',
		defaultMessage:
			'Related pages are on for this space, but suggestions will be hidden unless set to show on a page by someone with edit permissions.',
		description:
			'Description of related pages setting when they are turned on but will be hidden by default for entire space',
	},
	space_setting_off: {
		id: 'page-recommendations.eop.space.setting.off',
		defaultMessage: 'Related pages are off for this space.',
		description: 'Description of related pages setting when they are turned off for entire space',
	},
	space_setting_hidden: {
		id: 'page-recommendations.eop.space.setting.default.hidden',
		defaultMessage: 'Hidden on each page',
		description:
			'Description of related pages setting when the default behavior is to hide related pages',
	},
	space_setting_shown: {
		id: 'page-recommendations.eop.space.setting.default.shown',
		defaultMessage: 'Shown on each page',
		description:
			'Description of related pages setting when the default behavior is to show related pages',
	},
	feedbackMessage: {
		id: 'page-recommendations.related.pages.space.settings.feedback.section',
		defaultMessage: 'What do you think of related pages?',
		description:
			'Text displayed in a banner at the bottom of the related pages space settings tab asking for feedback.',
	},
	feedbackButtonText: {
		id: 'page-recommendations.related.pages.space.settings.feedback.button.text',
		defaultMessage: 'Give feedback',
		description: 'Text for button that triggers feedback for Related pages',
	},
	feedbackTitle: {
		id: 'page-recommendations.feedback.title.v2',
		defaultMessage: 'Thank you for trying out related pages',
		description: 'Title text for End Of Page Recs feedback form',
	},
	feedbackDescription: {
		id: 'page-recommendations.feedback.description',
		defaultMessage: 'How satisfied are you with related pages?',
		description: 'Description text for the End Of Page Recs feedback form',
	},
	feedbackFollowup: {
		id: 'page-recommendations.feedback.followup',
		defaultMessage: 'Thanks for the feedback! What do you like about it? What could be improved?',
		description: 'Text that prompts the user for more details on their rating',
	},
	feedbackSecondFollowup: {
		id: 'page-recommendations.feedback.followup.second',
		defaultMessage: 'Any feedback on the related pages for this specific page?',
		description: 'Second text that prompts the user for more details on their rating',
	},
	feedbackConfirmation: {
		id: 'page-recommendations.feedback.confirmation',
		defaultMessage: 'Feedback submitted',
		description: 'Title text for after the user submits feedback for End Of Page Recs',
	},
	feedbackConfirmationDescription: {
		id: 'page-recommendations.feedback.confirmation.description.v2',
		defaultMessage: 'Thank you for helping us improve related pages!',
		description: 'Description text for after the user submits feedback for End Of Page Recs',
	},
	feedbackSubmit: {
		id: 'page-recommendations.feedback.submit',
		defaultMessage: 'Submit',
		description: 'Button text for submit button for End Of Page Recs feedback form',
	},
	feedbackCancel: {
		id: 'page-recommendations.feedback.cancel',
		defaultMessage: 'Cancel',
		description: 'Button text for the cancel button for End Of Page Recs feedback form',
	},
	terrible: {
		id: 'page-recommendations.feedback.rating.terrible',
		defaultMessage: 'terrible',
		description: 'Tooltip to display for the feedback rating of 1',
	},
	bad: {
		id: 'page-recommendations.feedback.rating.bad',
		defaultMessage: 'bad',
		description: 'Tooltip to display for the feedback rating of 2',
	},
	okay: {
		id: 'page-recommendations.feedback.rating.okay',
		defaultMessage: 'okay',
		description: 'Tooltip to display for the feedback rating of 3',
	},
	good: {
		id: 'page-recommendations.feedback.rating.good',
		defaultMessage: 'good',
		description: 'Tooltip to display for the feedback rating of 4',
	},
	great: {
		id: 'page-recommendations.feedback.rating.great',
		defaultMessage: 'great',
		description: 'Tooltip to display for the feedback rating of 5',
	},
});
