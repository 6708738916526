import type { FC } from 'react';
import { useEffect, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';

export const ExportPLUpsellCohortEstimator: FC = () => {
	const { edition: confluenceEdition } = useSessionData();
	const { isSiteAdmin } = useContext(SPAViewContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		createAnalyticsEvent?.({
			type: 'sendUIEvent',
			data: {
				action: 'shown',
				actionSubject: 'popup',
				actionSubjectId: 'exportMenuPopup',
				source: 'contentToolsItem',
				attributes: {
					confluenceEdition,
					isSiteAdmin,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, confluenceEdition, isSiteAdmin]);

	return null;
};
